import React, { useEffect, useState } from 'react';
import { Section, Filter, EventCover, FilterDropDown } from 'components';
import axios from 'axios';

const EventList = (props) => {
  const { title, eventLocations } = props;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [result, setResult] = React.useState([]);
  const [filterName, setFilterName] = React.useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    let url = `${process.env.API_URL}/events?${
      filterName ? `location.title=${filterName}` : ''
    }&_sort=date:DESC&_limit=12&_start=${12 * currentPage}`;
    axios
      .get(url)
      .then(function (response) {
        setResult(response.data);
        var result = response.data;
        return result;
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [currentPage, filterName]);

  useEffect(() => {
    let url = `${process.env.API_URL}/events/count?${
      filterName ? `location.title=${filterName}` : ''
    }`;
    axios
      .get(url)
      .then(function (response) {
        setCount(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [filterName]);

  const handleLast = () => {
    setCurrentPage(Math.ceil(count / 12) - 1);
  };

  const handleFirst = () => {
    setCurrentPage(0);
  };

  const handleFilter = (selected) => {
    if (filterName === selected) {
      setFilterName('');
      setCurrentPage(0);
    } else {
      setFilterName(selected);
      setCurrentPage(0);
    }
  };

  const onClickPage = (i) => {
    if (i !== currentPage) {
      setCurrentPage(i);
    }
  };

  let pageArray = Array.from(
    { length: Math.ceil(count / 12) },
    (_, i) => i + 1
  );

  const pageButtons = pageArray.map((i, index) => {
    return (
      <button
        className='uk-button uk-button-default'
        onClick={() => {
          onClickPage(i - 1);
        }}
        key={index}
      >
        {i}
      </button>
    );
  });

  let minPage = Math.max(currentPage - 2, 0);
  let maxPage = Math.min(currentPage + 3, Math.ceil(count / 12));
  const fivePageButtons = pageButtons.slice(minPage, maxPage);

  return (
    <>
      <div className='uk-flex'>
        <div className='uk-width-expand'>
          <div className='uk-hidden@m'>
            <div className='uk-flex uk-flex-right uk-margin-medium-bottom'>
              <FilterDropDown
                filterList={eventLocations?.map(
                  (node, index) => node.node.title
                )}
                onClick={handleFilter}
              />
            </div>
          </div>
          <div className='uk-grid'>
            {result.length >= 1 ? (
              result.map((event, index) => {
                return <EventCover event={event} key={index} />;
              })
            ) : (
              <h4 className='uk-margin-large-bottom'>
                더 이상 이벤트가 없습니다.
              </h4>
            )}
          </div>
        </div>
        <div className='uk-visible@m'>
          <div className='uk-width-small'>
            <div className='uk-margin-small-left'>
              <Filter
                filterList={eventLocations?.map(
                  (node, index) => node.node.title
                )}
                onClick={handleFilter}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='uk-flex uk-flex-center'>
        <button
          className='uk-button uk-button-default uk-margin-small-right'
          onClick={handleFirst}
        >
          첫번째
        </button>
        {fivePageButtons}
        <button
          className='uk-button uk-button-default uk-margin-small-left'
          onClick={handleLast}
        >
          마지막
        </button>
      </div>
    </>
  );
};

export default EventList;
