import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Section, Carousel } from "components";

const DaegwanAreaContainer = (props) => {
  const { img, info, displayLeft, leftClick, rightClick } = props;

  const style = {
    borderTop: "2px solid #C2531A",
    height: "3.5vw",
    width: "10%",
    left: "-25px",
    position: "relative",
  };

  const description = (
    <div className="uk-width-expand" uk-scrollspy="cls: uk-animation-fade">
      <div className="uk-card uk-margin-small-bottom uk-height-medium">
        <div className="uk-card-body uk-flex-center">
          <div className="uk-flex uk-width-expand uk-flex-around uk-margin-medium-bottom">
            <div className="uk-button uk-button-text" onClick={leftClick}>
              <div className={`uk-h3 ${displayLeft ? "uk-text-bold" : ""}`}>
                울림 음악실
              </div>
            </div>
            <div className="uk-button uk-button-text" onClick={rightClick}>
              <div className={`uk-h3 ${!displayLeft ? "uk-text-bold" : ""}`}>
                세미나실
              </div>
            </div>
          </div>
          <hr style={style} />
          <p>{info}</p>
        </div>
      </div>
    </div>
  );
  const main_img = (
    <div className="uk-width-expand" uk-scrollspy="cls: uk-animation-fade">
      <div className="uk-card">
        {img ? (
          <div className="uk-card-media-top uk-flex uk-flex-center uk-overflow-hidden">
            <GatsbyImage image={img} alt="#" />
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    <>
      <div className="uk-p uk-width-expand">{description}</div>
      <div className="uk-width-expand uk-margin-medium-top">{main_img}</div>
    </>
  );
};
const Process = () => {
  return (
    <div className="uk-flex uk-flex-column">
      <div
        className="uk-h3 uk-text-center uk-margin-medium-bottom"
        uk-scrollspy="cls: uk-animation-fade"
      >
        대관신청절차 안내
      </div>
      <ol
        className="uk-margin-remove-top"
        uk-scrollspy="target: > li; cls: uk-animation-fade; delay: 200"
      >
        <li className="uk-p uk-margin-small-bottom">대관신청서 작성</li>
        <li className="uk-p">대관신청서 제출</li>
        <ul uk-scrollspy="target: > li; cls: uk-animation-fade; delay: 200">
          <li className="uk-p uk-margin-small-bottom">방문 제출</li>
          <li className="uk-p uk-margin-small-bottom">
            이메일: scc4002@naver.com
          </li>
          <li className="uk-p uk-margin-small-bottom">
            팩스: 033 - 636 - 8485
          </li>
          <li className="uk-p uk-margin-small-bottom">
            문의: 033 - 638 - 4002
          </li>
        </ul>
        <li className="uk-p uk-margin-small-bottom">대관 심의</li>
        <li className="uk-p uk-margin-small-bottom">대관 승인 통보</li>
      </ol>
    </div>
  );
};

const Application = (props) => {
  const { music_room_application, seminar_room_application } = props;
  return (
    <div
      className="uk-flex uk-flex-column"
      uk-scrollspy="cls: uk-animation-fade"
    >
      <div className="uk-h3 uk-text-center uk-margin-medium-bottom">
        대관 신청서
      </div>
      <div className="uk-flex uk-flex-between uk-margin-medium-bottom">
        <div className="uk-p">음악 연습실 대관 신청서</div>
        <a href={music_room_application} download="music-room-application">
          <button className="uk-button uk-button-default">다운로드</button>
        </a>
      </div>
      <div className="uk-flex uk-flex-between uk-margin-medium-bottom">
        <div className="uk-p">세미나 대관 신청서</div>
        <a href={seminar_room_application} download="seminar-room-application">
          <button className="uk-button uk-button-default">다운로드</button>
        </a>
      </div>
    </div>
  );
};
const Rules = () => {
  return (
    <Section title={"대관시 준수사항"}>
      <ol uk-scrollspy="target: > li; cls: uk-animation-fade; delay: 200">
        <li className="uk-p uk-margin-small-bottom">
          대관된 신청내용을 변경하거나 승인된 대관 사용권을 전대 또는 양도할 수
          없습니다
        </li>
        <li className="uk-p uk-margin-small-bottom">
          대관과 관련된 홍보, 판촉물을 설치할 경우 재단의 사전 승인을 받아
          설치해야 합니다
        </li>

        <li className="uk-p uk-margin-small-bottom">
          깨끗한 실내환경 조성을 위해 음료만 반입이 가능하며, 전기포트는 반입할
          수 없습니다
        </li>
        <li className="uk-p uk-margin-small-bottom" style={{ lineHeight: "2" }}>
          종교, 정치적인 목적, 기타 사회적 물의를 일으킬 소지가 있는 경우,
          상업적 영리를 목적으로 하는 경우, 타인을 방해할 소지가 있는 경우, 기타
          당 설악산책의 운영취지상 부적합하다고 판단되는 경우에는 대관이 제한될
          수 있습니다
        </li>
      </ol>
    </Section>
  );
};
function DaegwanInfo({ data }) {
  const music_room_desc = data.allStrapiDaegwan.nodes[0].music_room_desc;
  const seminar_room_desc = data.allStrapiDaegwan.nodes[0].seminar_room_desc;
  const music_room_img =
    data.allStrapiDaegwan.nodes[0].music_room_image.localFile.childImageSharp
      .gatsbyImageData;
  const seminar_room_img =
    data.allStrapiDaegwan.nodes[0].seminar_room_image.localFile.childImageSharp
      .gatsbyImageData;
  const music_room_application =
    data.strapiDaegwan.music_room_application[0].localFile.publicURL;
  const seminar_room_application =
    data.strapiDaegwan.seminar_room_application[0].localFile.publicURL;

  const [displayLeft, setDisplayLeft] = useState(true);

  function leftClick() {
    setDisplayLeft(true);
  }
  function rightClick() {
    setDisplayLeft(false);
  }
  const imgList = data.allStrapiDaegwan.nodes[0].carousel_img;
  return (
    <>
      <Carousel imgList={imgList} />
      <Section title={"대관실"}>
        <DaegwanAreaContainer
          info={displayLeft ? music_room_desc : seminar_room_desc}
          img={displayLeft ? music_room_img : seminar_room_img}
          displayLeft={displayLeft}
          leftClick={leftClick}
          rightClick={rightClick}
        />
      </Section>
      <Section>
        <div className="uk-grid uk-flex-around@m">
          <div className="uk-margin-medium uk-width-1-2@m">
            <Process />
          </div>
          <div className="uk-width-1-2@m">
            <Application
              music_room_application={music_room_application}
              seminar_room_application={seminar_room_application}
            />
          </div>
        </div>
      </Section>
      <div className="uk-background-muted">
        <Rules />
      </div>
    </>
  );
}

export default DaegwanInfo;
