import React from "react";
import Markdown from "react-markdown";
import { Section } from "components";
import { textContents } from "./content";
import { GatsbyImage } from "gatsby-plugin-image";

const Cafesori = (props) => {
  const { hours, address, menu, cafeImg, equipImg1, equipImg2 } = props;

  const schedule = (
    <Section title={"이용시간"} center>
      <div
        className=" uk-margin-xlarge-left uk-margin-xlarge-right"
        uk-scrollspy="cls: uk-animation-fade"
      >
        <div className="uk-h3 uk-text-bold uk-width-medium">카페소리</div>
        <div className="uk-flex uk-flex-column">
          <div className="uk-h4 uk-margin-remove-top">
            <Markdown source={hours} escapeHtml={false} />
          </div>
        </div>
        <div className="uk-h3 uk-text-bold uk-width-medium">주소</div>
        <div className="uk-h4 uk-margin-remove-top">{address}</div>
      </div>
    </Section>
  );

  const equipImages = (
    <div className="uk-flex" uk-scrollspy="cls: uk-animation-fade">
      <GatsbyImage
        image={equipImg1}
        alt=""
        style={{ width: "50%", maxHeight: "500px" }}
        imgStyle={{ objectFit: "cover" }}
      />
      <GatsbyImage
        image={equipImg2}
        alt=""
        style={{ width: "50%", maxHeight: "500px" }}
        imgStyle={{ objectFit: "cover" }}
      />
    </div>
  );

  const _soundOfCafe = textContents.map((content, index) => {
    return (
      <div
        className="uk-margin-xlarge-bottom"
        uk-scrollspy="cls: uk-animation-fade"
        key={index}
      >
        <div className="uk-h4 uk-text-bold">{content.header}</div>
        <p className="uk-h4">{content.description}</p>
        <a href={content.url}>
          <button className="uk-button uk-button-default uk-align-right">
            더보기
          </button>
        </a>
      </div>
    );
  });

  return (
    <>
      <Section title={"카페의 소리"}>{_soundOfCafe}</Section>
      {equipImages}
      <div uk-scrollspy="cls: uk-animation-fade">
        <Section title={"카페 메뉴"} center>
          <GatsbyImage
            image={menu}
            alt=""
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "cover" }}
          />
        </Section>
      </div>
      <div className="uk-grid" style={{ backgroundColor: "#F9F9F9" }}>
        <div className="uk-section uk-container uk-width-1-1@s uk-width-1-2@m">
          {schedule}
        </div>
        <div className="uk-flex uk-width-1-1@s uk-width-1-2@m">
          <GatsbyImage
            image={cafeImg}
            alt=""
            style={{ width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
      </div>
    </>
  );
};

export default Cafesori;
