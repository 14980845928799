import React from 'react';
import Markdown from 'react-markdown';
import { Section } from 'components';

const Hours = (props) => {
  const { data } = props;

  const sorakcc_hours = data.sorakcc_hours;
  const cafesori_hours = data.cafesori_hours;
  const hwaban_hours = data.hwaban_hours;
  const oolim_hours = data.oolim_hours;
  const notice = data.notice;

  const _schedule = (
    <div className='uk-flex uk-flex-column'>
      <div className='uk-grid uk-margin-small-bottom'>
        <div className='uk-h3 uk-text-bold uk-width-medium uk-margin-small-bottom'>
          설악산책
        </div>
        <div className='uk-h4 uk-margin-medium-right'>{sorakcc_hours}</div>
      </div>
      <div className='uk-grid uk-margin-small-bottom'>
        <div className='uk-h3 uk-text-bold uk-width-medium uk-margin-small-bottom'>
          카페소리
        </div>
        <div className='uk-h4 uk-flex-column'>
          <Markdown source={cafesori_hours} escapeHtml={false} />
        </div>
      </div>
      <div className='uk-grid uk-margin-small-bottom'>
        <div className='uk-h3 uk-text-bold uk-width-medium uk-margin-small-bottom'>
          화반
        </div>
        <div className='uk-h4'>
          <Markdown source={hwaban_hours} escapeHtml={false} />
        </div>
      </div>
      <div className='uk-grid uk-margin-medium-bottom'>
        <div className='uk-h3 uk-text-bold uk-width-medium uk-margin-small-bottom'>
          울림
        </div>
        <div className='uk-h4 uk-margin-small-right'>{oolim_hours}</div>
      </div>
    </div>
  );

  return (
    <Section title='이용시간'>
      {_schedule}
      <div className='uk-h6 uk-margin-top'>
        <Markdown source={notice} escapeHtml={false} />
      </div>
    </Section>
  );
};

export default Hours;
