import React, { useState } from 'react';
import { Section, Modal, ArtCard } from 'components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const ArtCollections = (props) => {
  const { arts } = props;

  const [modalData, setModalData] = useState('');
  const _arts = arts.nodes.map((art, index) => {
    const _image = art.art.localFile.childImageSharp.gatsbyImageData;
    return (
      <motion.div className='scrollanimate' initial='rest' whileHover='hover'>
        <motion.div
          className='uk-margin-bottom-medium uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden'
          key={index}
          onClick={() => setModalData(art)}
          variants={{
            hover: {
              scale: 1.04,
              transition: {
                duration: 0.5,
                type: 'tween',
                ease: 'easeInOut',
              },
            },
          }}
        >
          <a data-uk-toggle='target: #modal'>
            <GatsbyImage
              image={_image}
              alt=''
              style={{ height: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
            />
          </a>
        </motion.div>
      </motion.div>
    );
  });

  return (
    <Section title={'전시'}>
      <Modal children={<ArtCard data={modalData} />} />
      <div
        className='uk-child-width-1-2@s uk-child-width-1-3@m'
        uk-grid='masonry: true'
      >
        {_arts}
      </div>
    </Section>
  );
};

export default ArtCollections;
