import React from 'react';
import { Section } from 'components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

const ImageCard = (props) => {
  const { link, image, overlay } = props;
  let _image;
  if (link) {
    _image = (
      <Link to={link} className='uk-link-reset'>
        <GatsbyImage
          image={image}
          alt='#'
          imgStyle={{ filter: 'brightness(85%)' }}
        />
      </Link>
    );
  } else {
    _image = (
      <GatsbyImage
        image={image}
        alt='#'
        imgStyle={{ filter: 'brightness(85%)' }}
      />
    );
  }
  return (
    <motion.div
      className='uk-card uk-margin-small-bottom scrollanimate'
      initial='rest'
      whileHover='hover'
    >
      <div className='uk-card-media-top uk-flex uk-flex-middle uk-flex-center uk-overflow-hidden'>
        {overlay ? (
          <>
            <motion.div
              variants={{
                hover: {
                  scale: 1.1,
                  transition: {
                    duration: 0.5,
                    type: 'tween',
                    ease: 'easeInOut',
                  },
                },
              }}
            >
              {_image}
            </motion.div>
            <motion.div
              className='spaceinfo-overlay uk-overlay uk-position-bottom'
              variants={{
                rest: { opacity: 1 },
              }}
            >
              <div className='uk-h2 uk-text-bold uk-text-white'>{overlay}</div>
            </motion.div>
          </>
        ) : (
          <>{_image}</>
        )}
      </div>
    </motion.div>
  );
};
const ImageCardContainer = (props) => {
  const { space } = props;
  return (
    <div
      className='uk-grid uk-grid-small'
      uk-scrollspy='cls: uk-animation-fade; target: .scrollanimate; delay: 300'
    >
      <div className='uk-width-1-2@m'>
        <ImageCard
          link={'/cafesori'}
          image={
            space.cafesori_img_wide.localFile.childImageSharp.gatsbyImageData
          }
          overlay={'2F 카페소리'}
        />
      </div>
      <div className='uk-width-1-4 uk-visible@m'>
        <ImageCard
          image={
            space.cafesori_img_small_1.localFile.childImageSharp.gatsbyImageData
          }
        />
      </div>
      <div className='uk-width-1-4 uk-visible@m'>
        <ImageCard
          image={
            space.cafesori_img_small_2.localFile.childImageSharp.gatsbyImageData
          }
        />
      </div>
      <div className='uk-width-1-2@m'>
        <ImageCard
          link={'/hwaban'}
          image={
            space.hwaban_img_wide.localFile.childImageSharp.gatsbyImageData
          }
          overlay={'1F 화반'}
        />
      </div>
      <div className='uk-width-1-4 uk-visible@m'>
        <ImageCard
          image={
            space.hwaban_img_small_1.localFile.childImageSharp.gatsbyImageData
          }
        />
      </div>
      <div className='uk-width-1-4 uk-visible@m'>
        <ImageCard
          image={
            space.hwaban_img_small_2.localFile.childImageSharp.gatsbyImageData
          }
        />
      </div>
      <div className='uk-width-1-4 uk-visible@m'>
        <ImageCard
          image={
            space.sanchek_img_small_1.localFile.childImageSharp.gatsbyImageData
          }
        />
      </div>
      <div className='uk-width-1-2@m'>
        <ImageCard
          link={'/sanchek'}
          image={
            space.sanchek_img_wide.localFile.childImageSharp.gatsbyImageData
          }
          overlay={'1F 산&책'}
        />
      </div>
      <div className='uk-width-1-4 uk-visible@m'>
        <ImageCard
          image={
            space.sanchek_img_small_2.localFile.childImageSharp.gatsbyImageData
          }
        />
      </div>
      <div className='uk-width-1-4 uk-visible@m'>
        <ImageCard
          image={
            space.oolim_img_small_1.localFile.childImageSharp.gatsbyImageData
          }
        />
      </div>
      <div className='uk-width-1-4 uk-visible@m'>
        <ImageCard
          image={
            space.oolim_img_small_2.localFile.childImageSharp.gatsbyImageData
          }
        />
      </div>
      <div className='uk-width-1-2@m'>
        <ImageCard
          link={'/daegwan'}
          image={space.oolim_img_wide.localFile.childImageSharp.gatsbyImageData}
          overlay={'B1 울림'}
        />
      </div>
    </div>
  );
};

const SpaceInfo = (props) => {
  const { spaces } = props;
  const space = spaces.strapiHomepage.Images;

  return (
    <Section title={'공간 안내'}>
      <ImageCardContainer space={space} />
    </Section>
  );
};

export default SpaceInfo;
