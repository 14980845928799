// DUMMY DATA
import cafeInst from "images/cafe_instruments/cafe_instrument.png";

export const textContents = [
  {
    header: "음향 장비",
    description:
      "카페소리에는 유럽을 대표하는 빈티지 탄노이 스피커와 Mclntosh와 Jadis 진공관 앰프가 연 결되어 듣는 이로 하여금 웅장하면서 편안한 음악 감상을 하게 합니다.",
    url: "/cafe-instruments",
  },
  {
    header: "카페소리 공연",
    description:
      "카페소리는 지난 2013년 5월부터 꾸준히 공연을 기획하고 진행했습니다. 다양한 장르의 초청 공연 뿐 아니라 지역 음악인의 공연 지원 등 100회가 넘는 무료 공연을 선보였습니다.",
    url: "/events-all",
  },
];

export const cafeInstruments = {
  img: cafeInst,
};
