import React, { useEffect, useState } from 'react';
import { Section, BookCover, Filter, FilterDropDown } from 'components';
import axios from 'axios';
import qs from 'qs';

const BookList = (props) => {
  const { title, bookGenres } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [result, setResult] = useState([]);
  const [filterName, setFilterName] = useState('');

  const [searchKey, setSearchKey] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    let url = `${process.env.API_URL}/books?${
      filterName ? `genre.name=${filterName}` : ''
    }&_sort=title:ASC&_limit=12&_start=${12 * currentPage}`;
    axios
      .get(url)
      .then(function (response) {
        setResult(response.data);
        var result = response.data;
        return result;
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [currentPage, filterName]);

  useEffect(() => {
    let url = `${process.env.API_URL}/books/count?${
      filterName ? `genre.name=${filterName}` : ''
    }`;
    axios
      .get(url)
      .then(function (response) {
        setCount(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [filterName]);

  useEffect(() => {
    if (history.state) {
      setCurrentPage(history.state);
    }
  }, []);

  const onClickBook = () => {
    window.history.pushState(currentPage, '');
  };

  const handleLast = () => {
    setCurrentPage(Math.ceil(count / 12) - 1);
  };

  const handleFirst = () => {
    setCurrentPage(0);
  };

  const handleFilter = (selected) => {
    if (filterName === selected) {
      setFilterName('');
      setCurrentPage(0);
    } else {
      setFilterName(selected);
      setCurrentPage(0);
    }
  };

  const onChangeSearch = (event) => {
    setSearchKey(event.target.value);
  };

  const onSubmitSearch = () => {
    const query = qs.stringify({
      _where: {
        _or: [{ title_contains: searchKey }, { content_contains: searchKey }],
      },
    });

    let url = `${process.env.API_URL}/books?${query}`;
    axios
      .get(url)
      .then(function (response) {
        setResult(response.data);
        var result = response.data;
        return result;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onClickPage = (i) => {
    if (i !== currentPage) {
      setCurrentPage(i);
    }
  };

  let pageArray = Array.from(
    { length: Math.ceil(count / 12) },
    (_, i) => i + 1
  );

  const pageButtons = pageArray.map((i, index) => {
    return (
      <button
        className='uk-button uk-button-default'
        onClick={() => {
          onClickPage(i - 1);
        }}
        key={index}
      >
        {i}
      </button>
    );
  });

  let minPage = Math.max(currentPage - 2, 0);
  let maxPage = Math.min(currentPage + 3, Math.ceil(count / 12));
  const fivePageButtons = pageButtons.slice(minPage, maxPage);

  return (
    <Section title={title}>
      <div className='uk-flex uk-flex-right uk-margin-bottom'>
        <form className='uk-search uk-search-default uk-margin-small-right'>
          <input
            className='uk-search-input'
            type='search'
            placeholder='도서 검색'
            onChange={onChangeSearch}
            value={searchKey}
          />
        </form>
        <button className='uk-button uk-button-muted' onClick={onSubmitSearch}>
          <div className='uk-text-nowrap'>검색</div>
        </button>
        <div className='uk-margin-small-left uk-hidden@m'>
          <FilterDropDown
            filterList={bookGenres?.map((name, index) => name)}
            onClick={handleFilter}
          />
        </div>
      </div>
      <div className='uk-flex'>
        <div className='uk-width-expand'>
          <div className='uk-grid'>
            {result.length >= 1 ? (
              result.map((book, index) => {
                return (
                  <BookCover book={book} key={index} onClick={onClickBook()} />
                );
              })
            ) : (
              <h4>더 이상 도서가 없습니다.</h4>
            )}
          </div>
        </div>
        <div className='uk-visible@m'>
          <div className='uk-width-small'>
            <Filter
              filterList={bookGenres?.map((name, index) => name)}
              onClick={handleFilter}
            />
          </div>
        </div>
      </div>
      <div className='uk-flex uk-flex-center'>
        <button
          className='uk-button uk-button-default uk-margin-small-right'
          onClick={handleFirst}
        >
          첫번째
        </button>

        {fivePageButtons}
        <button
          className='uk-button uk-button-default uk-margin-small-left'
          onClick={handleLast}
        >
          마지막
        </button>
      </div>
    </Section>
  );
};

export default BookList;
