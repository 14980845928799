import React from 'react';
import { Section } from 'components';
import { Markdown } from 'components';

const Foundation = (props) => {
  const {
    introduction,
    establishment,
    history,
    donation,
    donation_title,
    settlement,
    settlement_title,
    office_of_education,
  } = props;

  return (
    <>
      <div className='uk-padding-large'></div>
      <Section title={'재단소개'}>
        <div uk-scrollspy='cls: uk-animation-fade'>
          <Markdown data={introduction} />
        </div>
      </Section>
      <Section title={'설립목적'}>
        <div uk-scrollspy='cls: uk-animation-fade'>
          <Markdown data={establishment} />
        </div>
      </Section>
      <Section title={'연혁'}>
        <div uk-scrollspy='cls: uk-animation-fade'>
          <Markdown data={history} />
        </div>
      </Section>
      <Section title={'결산서'}>
        <div
          className='uk-h3 uk-text-primary'
          uk-scrollspy='cls: uk-animation-fade'
        >
          <a className='uk-link-heading' href={settlement}>
            {settlement_title}
          </a>
        </div>
      </Section>
      <Section title={'기부금'}>
        <div
          className='uk-h3 uk-text-primary'
          uk-scrollspy='cls: uk-animation-fade'
        >
          <a className='uk-link-heading' href={donation}>
            {donation_title}
          </a>
        </div>
      </Section>
      <Section title={'강원교육청 공익신고 홈페이지'}>
        <div
          className='uk-h3'
          uk-scrollspy='cls: uk-animation-fade'
          style={{ color: '#C2531A' }}
        >
          <a className='uk-link-text'>
            <Markdown
              data={office_of_education}
              paragraphClass={'uk-h3 uk-text-primary'}
              escapeHtml={false}
            />
          </a>
        </div>
      </Section>
    </>
  );
};

export default Foundation;
