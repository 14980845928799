import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";

const Announcements = (props) => {
  const { PressReleases } = props;

  const _pressReleases = PressReleases.map((item, index) => {
    return (
      <Link
        to={`/press-release/${item.node.strapiId}`}
        className="uk-link-reset"
      >
        <motion.div
          whileHover={{
            scale: 1.04,
          }}
        >
          <div className="uk-margin-medium-bottom" key={index}>
            {item.node.title}
          </div>
        </motion.div>
      </Link>
    );
  });
  return (
    <>
      <h4
        className="uk-flex-column uk-h4 uk-margin-bottom"
        uk-scrollspy="cls: uk-animation-fade"
      >
        {_pressReleases}
      </h4>
    </>
  );
};

export default Announcements;
